
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46catchAll_93aVxpuwIgenMeta } from "/codebuild/output/src1314014856/src/storefront/pages/[...catchAll].vue?macro=true";
import { default as about_45usUWk9Lwi8KOMeta } from "/codebuild/output/src1314014856/src/storefront/pages/about-us.vue?macro=true";
import { default as barion_45startYCfxroG5K2Meta } from "/codebuild/output/src1314014856/src/storefront/pages/barion-start.vue?macro=true";
import { default as bestsellers_45of_45the_45month0UZnqzOoq9Meta } from "/codebuild/output/src1314014856/src/storefront/pages/bestsellers-of-the-month.vue?macro=true";
import { default as book_45clubMra9k2vokKMeta } from "/codebuild/output/src1314014856/src/storefront/pages/book-club.vue?macro=true";
import { default as _91slug_93hKZsbKTTGuMeta } from "/codebuild/output/src1314014856/src/storefront/pages/book/[slug].vue?macro=true";
import { default as _91slug_939j05ugDierMeta } from "/codebuild/output/src1314014856/src/storefront/pages/books-by-author/[slug].vue?macro=true";
import { default as indexBOnfCGV3aUMeta } from "/codebuild/output/src1314014856/src/storefront/pages/books/[category]/[subcategory]/index.vue?macro=true";
import { default as indexzkCuOPJrfRMeta } from "/codebuild/output/src1314014856/src/storefront/pages/books/[category]/index.vue?macro=true";
import { default as indexLdoVrwkSWRMeta } from "/codebuild/output/src1314014856/src/storefront/pages/books/index.vue?macro=true";
import { default as index3CxOTtCuhEMeta } from "/codebuild/output/src1314014856/src/storefront/pages/checkout/index.vue?macro=true";
import { default as thankyou9KcQMUFitsMeta } from "/codebuild/output/src1314014856/src/storefront/pages/checkout/thankyou.vue?macro=true";
import { default as contact_45us1yYODQkcOfMeta } from "/codebuild/output/src1314014856/src/storefront/pages/contact-us.vue?macro=true";
import { default as cookie80ViyTWUZuMeta } from "/codebuild/output/src1314014856/src/storefront/pages/cookie.vue?macro=true";
import { default as indexZ7BXy7Qnl7Meta } from "/codebuild/output/src1314014856/src/storefront/pages/index.vue?macro=true";
import { default as loginhnyFwSXthKMeta } from "/codebuild/output/src1314014856/src/storefront/pages/login.vue?macro=true";
import { default as _91slug_93mKFw8gxKQBMeta } from "/codebuild/output/src1314014856/src/storefront/pages/menu-navigation/[slug].vue?macro=true";
import { default as my_45cart8aqhEohhxkMeta } from "/codebuild/output/src1314014856/src/storefront/pages/my-cart.vue?macro=true";
import { default as _91slug_93LxH36i6BcIMeta } from "/codebuild/output/src1314014856/src/storefront/pages/my-orders/[slug].vue?macro=true";
import { default as indexEg5tDpBiC5Meta } from "/codebuild/output/src1314014856/src/storefront/pages/my-orders/index.vue?macro=true";
import { default as newly_45publishedIM0jNTHIhHMeta } from "/codebuild/output/src1314014856/src/storefront/pages/newly-published.vue?macro=true";
import { default as newspapersurKbGmwsjrMeta } from "/codebuild/output/src1314014856/src/storefront/pages/newspapers.vue?macro=true";
import { default as on_45sale3kGmUEbLagMeta } from "/codebuild/output/src1314014856/src/storefront/pages/on-sale.vue?macro=true";
import { default as _91slug_93leUmVYf1TaMeta } from "/codebuild/output/src1314014856/src/storefront/pages/other-language/[slug].vue?macro=true";
import { default as indexfzouZGvkmqMeta } from "/codebuild/output/src1314014856/src/storefront/pages/other-languages/[category]/[subcategory]/index.vue?macro=true";
import { default as indextIoKERPY42Meta } from "/codebuild/output/src1314014856/src/storefront/pages/other-languages/[category]/index.vue?macro=true";
import { default as indexlgrhumtQPXMeta } from "/codebuild/output/src1314014856/src/storefront/pages/other-languages/index.vue?macro=true";
import { default as payment_45resultK12Uv9IS5qMeta } from "/codebuild/output/src1314014856/src/storefront/pages/payment-result.vue?macro=true";
import { default as paypal_45startFVKKdrx0uLMeta } from "/codebuild/output/src1314014856/src/storefront/pages/paypal-start.vue?macro=true";
import { default as privacy_45policyI7yl79LLYxMeta } from "/codebuild/output/src1314014856/src/storefront/pages/privacy-policy.vue?macro=true";
import { default as _91_91subcategory_93_93f28Agcak6RMeta } from "/codebuild/output/src1314014856/src/storefront/pages/products/[[category]]/[[subcategory]].vue?macro=true";
import { default as purchase_45and_45shipping_45conditions7amPoJVG7sMeta } from "/codebuild/output/src1314014856/src/storefront/pages/purchase-and-shipping-conditions.vue?macro=true";
import { default as searchNDejkcIRLbMeta } from "/codebuild/output/src1314014856/src/storefront/pages/search.vue?macro=true";
import { default as staff_45recommendationsYsyhA7W0yyMeta } from "/codebuild/output/src1314014856/src/storefront/pages/staff-recommendations.vue?macro=true";
import { default as _91slug_93DxiNbo7qycMeta } from "/codebuild/output/src1314014856/src/storefront/pages/stationary-and-gift/[slug].vue?macro=true";
import { default as index7imznRJQo0Meta } from "/codebuild/output/src1314014856/src/storefront/pages/stationary-and-gifts/[category]/[subcategory]/index.vue?macro=true";
import { default as indexhRhuMVC4lBMeta } from "/codebuild/output/src1314014856/src/storefront/pages/stationary-and-gifts/[category]/index.vue?macro=true";
import { default as indexMwJuKmI1F3Meta } from "/codebuild/output/src1314014856/src/storefront/pages/stationary-and-gifts/index.vue?macro=true";
import { default as terms_45and_45conditionspztSMnjv4BMeta } from "/codebuild/output/src1314014856/src/storefront/pages/terms-and-conditions.vue?macro=true";
import { default as upcoming_45releasesoAw5YzolyLMeta } from "/codebuild/output/src1314014856/src/storefront/pages/upcoming-releases.vue?macro=true";
export default [
  {
    name: "catchAll___hu",
    path: "/hu/:catchAll(.*)*",
    meta: _91_46_46_46catchAll_93aVxpuwIgenMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/[...catchAll].vue")
  },
  {
    name: "catchAll___en",
    path: "/:catchAll(.*)*",
    meta: _91_46_46_46catchAll_93aVxpuwIgenMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/[...catchAll].vue")
  },
  {
    name: "about-us___hu",
    path: "/hu/rolunk",
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/about-us.vue")
  },
  {
    name: "about-us___en",
    path: "/about-us",
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/about-us.vue")
  },
  {
    name: "barion-start___hu",
    path: "/hu/barion-fizetes",
    meta: barion_45startYCfxroG5K2Meta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/barion-start.vue")
  },
  {
    name: "barion-start___en",
    path: "/barion-start",
    meta: barion_45startYCfxroG5K2Meta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/barion-start.vue")
  },
  {
    name: "bestsellers-of-the-month___hu",
    path: "/hu/a-honap-sikerkonyvei",
    meta: bestsellers_45of_45the_45month0UZnqzOoq9Meta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/bestsellers-of-the-month.vue")
  },
  {
    name: "bestsellers-of-the-month___en",
    path: "/bestsellers-of-the-month",
    meta: bestsellers_45of_45the_45month0UZnqzOoq9Meta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/bestsellers-of-the-month.vue")
  },
  {
    name: "book-club___hu",
    path: "/hu/konyvklub",
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/book-club.vue")
  },
  {
    name: "book-club___en",
    path: "/book-club",
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/book-club.vue")
  },
  {
    name: "book-slug___hu",
    path: "/hu/konyv/:slug()",
    meta: _91slug_93hKZsbKTTGuMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/book/[slug].vue")
  },
  {
    name: "book-slug___en",
    path: "/book/:slug()",
    meta: _91slug_93hKZsbKTTGuMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/book/[slug].vue")
  },
  {
    name: "books-by-author-slug___hu",
    path: "/hu/szerzo-muvei/:slug()",
    meta: _91slug_939j05ugDierMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/books-by-author/[slug].vue")
  },
  {
    name: "books-by-author-slug___en",
    path: "/books-by-author/:slug()",
    meta: _91slug_939j05ugDierMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/books-by-author/[slug].vue")
  },
  {
    name: "books-category-subcategory___hu",
    path: "/hu/konyvek/:category()/:subcategory()",
    meta: indexBOnfCGV3aUMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/books/[category]/[subcategory]/index.vue")
  },
  {
    name: "books-category-subcategory___en",
    path: "/books/:category()/:subcategory()",
    meta: indexBOnfCGV3aUMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/books/[category]/[subcategory]/index.vue")
  },
  {
    name: "books-category___hu",
    path: "/hu/konyvek/:category()",
    meta: indexzkCuOPJrfRMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/books/[category]/index.vue")
  },
  {
    name: "books-category___en",
    path: "/books/:category()",
    meta: indexzkCuOPJrfRMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/books/[category]/index.vue")
  },
  {
    name: "books___hu",
    path: "/hu/konyvek",
    meta: indexLdoVrwkSWRMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/books/index.vue")
  },
  {
    name: "books___en",
    path: "/books",
    meta: indexLdoVrwkSWRMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/books/index.vue")
  },
  {
    name: "checkout___hu",
    path: "/hu/megrendeles",
    meta: index3CxOTtCuhEMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/checkout/index.vue")
  },
  {
    name: "checkout___en",
    path: "/checkout",
    meta: index3CxOTtCuhEMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/checkout/index.vue")
  },
  {
    name: "checkout-thankyou___hu",
    path: "/hu/megrendeles-koszonjuk",
    meta: thankyou9KcQMUFitsMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/checkout/thankyou.vue")
  },
  {
    name: "checkout-thankyou___en",
    path: "/checkout-thankyou",
    meta: thankyou9KcQMUFitsMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/checkout/thankyou.vue")
  },
  {
    name: "contact-us___hu",
    path: "/hu/kapcsolat",
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/contact-us.vue")
  },
  {
    name: "contact-us___en",
    path: "/contact-us",
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/contact-us.vue")
  },
  {
    name: "cookie___hu",
    path: "/hu/suti",
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/cookie.vue")
  },
  {
    name: "cookie___en",
    path: "/cookie",
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/cookie.vue")
  },
  {
    name: "index___hu",
    path: "/hu",
    meta: indexZ7BXy7Qnl7Meta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    meta: indexZ7BXy7Qnl7Meta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/index.vue")
  },
  {
    name: "login___hu",
    path: "/hu/bejelentkezes",
    meta: loginhnyFwSXthKMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/login.vue")
  },
  {
    name: "login___en",
    path: "/login",
    meta: loginhnyFwSXthKMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/login.vue")
  },
  {
    name: "menu-navigation-slug___hu",
    path: "/hu/menu-navigacio/:slug()",
    meta: _91slug_93mKFw8gxKQBMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/menu-navigation/[slug].vue")
  },
  {
    name: "menu-navigation-slug___en",
    path: "/menu-navigation/:slug()",
    meta: _91slug_93mKFw8gxKQBMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/menu-navigation/[slug].vue")
  },
  {
    name: "my-cart___hu",
    path: "/hu/kosaram",
    meta: my_45cart8aqhEohhxkMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/my-cart.vue")
  },
  {
    name: "my-cart___en",
    path: "/my-cart",
    meta: my_45cart8aqhEohhxkMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/my-cart.vue")
  },
  {
    name: "my-orders-slug___hu",
    path: "/hu/rendeleseim/:slug()",
    meta: _91slug_93LxH36i6BcIMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/my-orders/[slug].vue")
  },
  {
    name: "my-orders-slug___en",
    path: "/my-orders/:slug()",
    meta: _91slug_93LxH36i6BcIMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/my-orders/[slug].vue")
  },
  {
    name: "my-orders___hu",
    path: "/hu/rendeleseim",
    meta: indexEg5tDpBiC5Meta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/my-orders/index.vue")
  },
  {
    name: "my-orders___en",
    path: "/my-orders",
    meta: indexEg5tDpBiC5Meta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/my-orders/index.vue")
  },
  {
    name: "newly-published___hu",
    path: "/hu/ujdonsagok",
    meta: newly_45publishedIM0jNTHIhHMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/newly-published.vue")
  },
  {
    name: "newly-published___en",
    path: "/newly-published",
    meta: newly_45publishedIM0jNTHIhHMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/newly-published.vue")
  },
  {
    name: "newspapers___hu",
    path: "/hu/ujsagok-es-magazinok",
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/newspapers.vue")
  },
  {
    name: "newspapers___en",
    path: "/newspapers-and-magazines",
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/newspapers.vue")
  },
  {
    name: "on-sale___hu",
    path: "/hu/akciok",
    meta: on_45sale3kGmUEbLagMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/on-sale.vue")
  },
  {
    name: "on-sale___en",
    path: "/on-sale",
    meta: on_45sale3kGmUEbLagMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/on-sale.vue")
  },
  {
    name: "other-language-slug___hu",
    path: "/hu/egyeb-idegen-nyelvu-konyv/:slug()",
    meta: _91slug_93leUmVYf1TaMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/other-language/[slug].vue")
  },
  {
    name: "other-language-slug___en",
    path: "/other-language/:slug()",
    meta: _91slug_93leUmVYf1TaMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/other-language/[slug].vue")
  },
  {
    name: "other-languages-category-subcategory___hu",
    path: "/hu/egyeb-idegen-nyelvu-konyvek/:categorysubcategory?",
    meta: indexfzouZGvkmqMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/other-languages/[category]/[subcategory]/index.vue")
  },
  {
    name: "other-languages-category-subcategory___en",
    path: "/other-languages/:category()/:subcategory()",
    meta: indexfzouZGvkmqMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/other-languages/[category]/[subcategory]/index.vue")
  },
  {
    name: "other-languages-category___hu",
    path: "/hu/egyeb-idegen-nyelvu-konyvek/:category()",
    meta: indextIoKERPY42Meta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/other-languages/[category]/index.vue")
  },
  {
    name: "other-languages-category___en",
    path: "/other-languages/:category()",
    meta: indextIoKERPY42Meta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/other-languages/[category]/index.vue")
  },
  {
    name: "other-languages___hu",
    path: "/hu/egyeb-idegen-nyelvu-konyvek",
    meta: indexlgrhumtQPXMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/other-languages/index.vue")
  },
  {
    name: "other-languages___en",
    path: "/other-languages",
    meta: indexlgrhumtQPXMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/other-languages/index.vue")
  },
  {
    name: "payment-result___hu",
    path: "/hu/barion-eredmeny",
    meta: payment_45resultK12Uv9IS5qMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/payment-result.vue")
  },
  {
    name: "payment-result___en",
    path: "/barion-result",
    meta: payment_45resultK12Uv9IS5qMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/payment-result.vue")
  },
  {
    name: "paypal-start___hu",
    path: "/hu/paypal-fizetes",
    meta: paypal_45startFVKKdrx0uLMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/paypal-start.vue")
  },
  {
    name: "paypal-start___en",
    path: "/paypal-start",
    meta: paypal_45startFVKKdrx0uLMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/paypal-start.vue")
  },
  {
    name: "privacy-policy___hu",
    path: "/hu/adatkezelesi-tajekoztato",
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/privacy-policy.vue")
  },
  {
    name: "products-category-subcategory___hu",
    path: "/hu/product/:category?/:subcategory?",
    meta: _91_91subcategory_93_93f28Agcak6RMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/products/[[category]]/[[subcategory]].vue")
  },
  {
    name: "products-category-subcategory___en",
    path: "/products/:category?/:subcategory?",
    meta: _91_91subcategory_93_93f28Agcak6RMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/products/[[category]]/[[subcategory]].vue")
  },
  {
    name: "purchase-and-shipping-conditions___hu",
    path: "/hu/vasarlasi-es-szallitasi-feltetelek",
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/purchase-and-shipping-conditions.vue")
  },
  {
    name: "purchase-and-shipping-conditions___en",
    path: "/purchase-and-shipping-conditions",
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/purchase-and-shipping-conditions.vue")
  },
  {
    name: "search___hu",
    path: "/hu/kereses",
    meta: searchNDejkcIRLbMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/search.vue")
  },
  {
    name: "search___en",
    path: "/search",
    meta: searchNDejkcIRLbMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/search.vue")
  },
  {
    name: "staff-recommendations___hu",
    path: "/hu/mi-ajanljuk",
    meta: staff_45recommendationsYsyhA7W0yyMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/staff-recommendations.vue")
  },
  {
    name: "staff-recommendations___en",
    path: "/staff-recommendations",
    meta: staff_45recommendationsYsyhA7W0yyMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/staff-recommendations.vue")
  },
  {
    name: "stationary-and-gift-slug___hu",
    path: "/hu/notesz-es-ajandektargy/:slug()",
    meta: _91slug_93DxiNbo7qycMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/stationary-and-gift/[slug].vue")
  },
  {
    name: "stationary-and-gift-slug___en",
    path: "/stationary-and-gift/:slug()",
    meta: _91slug_93DxiNbo7qycMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/stationary-and-gift/[slug].vue")
  },
  {
    name: "stationary-and-gifts-category-subcategory___hu",
    path: "/hu/noteszek-es-ajandektargyak/:categorysubcategory?",
    meta: index7imznRJQo0Meta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/stationary-and-gifts/[category]/[subcategory]/index.vue")
  },
  {
    name: "stationary-and-gifts-category-subcategory___en",
    path: "/stationary-and-gifts/:category()/:subcategory()",
    meta: index7imznRJQo0Meta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/stationary-and-gifts/[category]/[subcategory]/index.vue")
  },
  {
    name: "stationary-and-gifts-category___hu",
    path: "/hu/noteszek-es-ajandektargyak/:category()",
    meta: indexhRhuMVC4lBMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/stationary-and-gifts/[category]/index.vue")
  },
  {
    name: "stationary-and-gifts-category___en",
    path: "/stationary-and-gifts/:category()",
    meta: indexhRhuMVC4lBMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/stationary-and-gifts/[category]/index.vue")
  },
  {
    name: "stationary-and-gifts___hu",
    path: "/hu/noteszek-es-ajandektargyak",
    meta: indexMwJuKmI1F3Meta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/stationary-and-gifts/index.vue")
  },
  {
    name: "stationary-and-gifts___en",
    path: "/stationary-and-gifts",
    meta: indexMwJuKmI1F3Meta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/stationary-and-gifts/index.vue")
  },
  {
    name: "terms-and-conditions___hu",
    path: "/hu/altalanos-szerzodesi-feltetelek",
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___en",
    path: "/terms-and-conditions",
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/terms-and-conditions.vue")
  },
  {
    name: "upcoming-releases___hu",
    path: "/hu/varhato-megjelenesek",
    meta: upcoming_45releasesoAw5YzolyLMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/upcoming-releases.vue")
  },
  {
    name: "upcoming-releases___en",
    path: "/upcoming-releases",
    meta: upcoming_45releasesoAw5YzolyLMeta || {},
    component: () => import("/codebuild/output/src1314014856/src/storefront/pages/upcoming-releases.vue")
  }
]